  [v-cloak] {
    display: none;
  }

// Additional Global Styles
h1,h2,h3,h4,h5,h6 {
  &.primary {
    color: $primary-color;
  }
  &.secondary {
    color: $secondary-color;
  }
}

.button {
  font-weight: $global-weight-bold;
}

// Header Styles
header {
  padding: 2rem 0;
  border-top: 5px solid $medium-gray;
}

// Page Content
.content-container {
  border: 2px solid $primary-color;
  padding: 2rem;
  border-radius: $global-radius;
  position: relative;
  overflow: hidden;


  .cell {
    z-index: 1;
  }

  .content-bg {
    z-index: 0;
    background: $light-gray;
    border-top-right-radius: 90%;
    border-bottom-right-radius: 90%;
    position: absolute;
    top: -35%;
    left: 0;
    height: 150%;
    width: 55%;

    &.bg-image {
      background: url('../img/BG_download.jpg') no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
}

// Form Checkboxes

.checklist {
  margin-left: 0;
  margin-bottom: 0;

  li {
    list-style: none;
    position: relative;
    margin: 0.5rem 0;
    
    label {
      padding-left: 1.5rem;
    }

    input {
      &.checkbox[type=checkbox] {
        width: 19px;
        height: 19px;
        text-indent: 2.5px;
        display:none !important;	
      }

      &.checkbox[type=checkbox] + *:before {
        content: '';
        display: inline-block;
        width: 19px;
        height: 19px;
        vertical-align: middle;
        text-align: center;
        background: url('../img/checkbox-unchecked.svg') no-repeat;
        position: absolute;
        left: 0;
        top: 3px;
      }

      &.checkbox[type=checkbox]:checked + *:before {
        content: '';
        background: url('../img/checkbox-checked.svg') no-repeat;
      }
    }
  }
}

// Tables
.table-container, .totals-container {
  padding: 1rem;
  background: $light-gray;
  margin-bottom: 1rem;
  border-radius: $global-radius;

  table {
    thead {
      tr {
        th {
          background: $primary-color;
          color: $white;
          border: 2px solid $primary-color;
          border-right: 2px solid $white;

          &:last-child {
            border-right: none;
          }
        }
      }
    }

    tbody {
      tr {
        background: transparent;

        td {
          border: 2px solid $primary-color;
        }
      }
    }

    &.totals {
      tbody {
        tr {
          background: transparent;
  
          td {
            border: 2px solid $primary-color;
            color: $primary-color;
            font-weight: $global-weight-bold;
          }
        }
      }
    }

    li {
      margin: 0;
    }
  }
}
.totals-container {
  background: transparent;
  table {
    float: right;
    max-width: rem-calc(350px);
  }
}

// Footer
footer {
  padding: 2rem 0;
  font-size: rem-calc(11px);
  color: $primary-color;
}